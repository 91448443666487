import produce from "immer"
export const initialState = {
  form: {
    name: "",
    companions: [],
  },
}
export const reducer = produce((draft, action) => {
  switch (action.type) {
    case "SET": {
      draft.form = action.value
      return draft
    }
    case "SET_FORM": {
      draft.form[action.value.key] = action.value.value
      return draft
    }
    case "ADD_COMPANION": {
      draft.form.companions.push({})
      return draft
    }
  }
})
