import React, { createContext, useContext, useReducer } from "react";
import { reducer, initialState } from "./reducer";

const MyContext = createContext<any>([]);

export const MyProvider = ({ children }: { children: React.ReactNode }) => {
  const contextValue = useReducer(reducer, initialState);
  return (
    <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>
  );
};

export const useMyReducer = () => {
  return useContext(MyContext);
};
