import React from "react"
import "../../App.css"
import { BookForm } from "../components/BookForm"
import { MyProvider } from "../components/context"

function App({ tours }) {
  return (
    <div className="App">
      <MyProvider>
        <BookForm tours={tours} />
      </MyProvider>
    </div>
  )
}

export default App
