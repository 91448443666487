import React, { useCallback, useEffect, useState } from "react"
// import "../components/App.css"
import {
  Button,
  Card,
  Elevation,
  FormGroup,
  InputGroup,
  HTMLSelect,
  TextArea,
  Intent,
  Overlay,
} from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime"
import MomentLocaleUtils from "react-day-picker/moment"
import "moment/locale/ja"
import * as moment from "moment"
import styled from "styled-components"
import { useMyReducer } from "../components/context"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import hero from "../images/hero.png"

interface IOverlayCard {
  width: number
}

// Markdown2HTML
import showdown from "showdown"
const converter = new showdown.Converter()
const MarkdownContent = ({ children = "" }) => (
  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(children) }} />
)

const encode = (data: any) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Styled = {
  Hero: styled.div`
    height: 400px;
    max-width: 840px;
    background-image: url(${hero});
    filter: blur(2px);
  `,
  HeroText: styled.div`
    position: absolute;
    width: 100%;
    max-width: 840px;
    top: 0;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    * {
      color: white;
    }
    border-bottom: 2px solid #cdcdcd;
  `,
  Main: styled.div`
    margin: 10px;
  `,
  Section: styled.div`
    margin-top: 10px;
  `,
  Form: styled.form`
    margin: 0 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 840px;
    width: 100%;
  `,
  OverlayCard: styled(Card)<IOverlayCard>`
    left: calc(50vw - ${props => props.width / 2}px);
    margin: 10vh 0;
    top: 0;
    padding: 0;
    /* width: 360px; */
  `,
  PaymentIframe: styled.iframe`
    border: 0;
  `,
}

export const Row: React.FC<any> = ({ title, value }) => {
  return (
    <div>
      <h5 className="bp5-heading">{title}</h5>
      <p>{value}</p>
    </div>
  )
}

const Template = {
  PC: "templateD",
  SP: "mobile-iframe",
}

export const Thanks: React.FC<any> = () => {
  const [context, dispatch] = useMyReducer()
  return (
    <Styled.Form>
      <Styled.Hero></Styled.Hero>
      <Styled.HeroText>
        <h2 className="bp3-heading">ジョイナビ - 予約フォーム</h2>
      </Styled.HeroText>
      <Styled.Main>
        <p>
          お問い合わせありがとうございました。2営業日以内にご連絡差し上げます。
        </p>

        <Row title="ツアー名" value={context.form.tour} />

        <Row
          title="お名前"
          value={context.form.lastName + context.form.firstName}
        />

        <Row title="お名前(ローマ字)" value={context.form.nameRoma} />

        <Row title="メールアドレス" value={context.form.email} />

        <Row
          title="現地での連絡手段(Line IDなど)"
          value={context.form.contactTool}
        />

        <Row
          title="希望日"
          value={
            context.form.date
              ? moment(context.form.date).format("YYYY/MM/DD")
              : ""
          }
        />

        <Row title="お迎え先" value={context.form.pickupPoint} />

        <h3 className="bp4-heading">同行者</h3>
        {context.form.companions ? (
          context.form.companions.map((o, i) => {
            return (
              <div>
                <h4 className="bp5-heading">{i}人目</h4>
                <Row title="性別" value={o.sex} />
                <Row
                  title="誕生日"
                  value={
                    o.birthday ? moment(o.birthday).format("YYYY/MM/DD") : ""
                  }
                />
              </div>
            )
          })
        ) : (
          <p>同行者無し</p>
        )}

        <Row title="連絡事項" value={context.form.remark} />
      </Styled.Main>
    </Styled.Form>
  )
}

function getItemByKey(tour, key) {
  try {
    return tour.node.childMarkdownRemark.frontmatter[key]
  } catch (err) {
    return ""
  }
}

export const BookForm: React.FC<any> = ({ tours }) => {
  const [isPc, setIsPc] = useState(true)
  const [userInput, setUserInput] = useState({})
  const [counter, setCounter] = useState(0)
  const [context, dispatch] = useMyReducer()
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const onClosePaymentModal = () => {
    setIsPaymentModalOpen(false)
  }
  const [state, setState] = useState("form")
  const [cardElement, setCardElement] = useState(null)
  const { handleSubmit, register, watch, errors, control } = useForm({
    // defaultValues: {
    //   lastName: "サンプル",
    //   firstName: "サンプル",
    //   nameRoma: "サンプル",
    //   birthday: new Date(),
    //   tel: "09099999999",
    //   email: "kota.fullsour@gmail.com",
    // },
  })
  useEffect(() => {
    const updateWindowDimensions = () => {
      const width = window.innerWidth
      setIsPc(width > 640)
    }
    window.addEventListener("resize", updateWindowDimensions)
    updateWindowDimensions()

    return () => window.removeEventListener("resize", updateWindowDimensions)
  }, [])

  const watchTour = watch("tour", null)
  const watchFirstName = watch("firstName", "")
  const watchLastName = watch("lastName", "")
  const activeTour = watchTour
    ? tours.find(o => o.node.childMarkdownRemark.frontmatter.name === watchTour)
    : null
  const { fields, append, remove } = useFieldArray({
    control,
    name: "companions",
  })
  const onSubmit = async values => {
    setIsPaymentModalOpen(true)
    if (confirm("決済画面に遷移します")) {
      setUserInput(values)
      document.form_iframe.submit()
    }
  }

  const onPressAddCompanions = useCallback(ev => {
    append({ name: "companions" })
  }, [])

  if (state === "form") {
    return (
      <div>
        <Overlay
          isOpen={isPaymentModalOpen}
          onClose={onClosePaymentModal}
          canOutsideClickClose
        >
          <Styled.OverlayCard
            elevation={Elevation.TWO}
            width={isPc ? 600 : 280}
          >
            <Styled.PaymentIframe
              name="hss_iframe"
              width={isPc ? "600" : "280"}
              height={isPc ? "450" : "420"}
              sandbox="allow-top-navigation-by-user-activation allow-popups-to-escape-sandbox allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation"
              onLoad={e => {
                console.log(counter)
                setCounter(counter + 1)
                // console.log(e.currentTarget.contentWindow.location.href)
                if (counter > 1) makeRequest(userInput)
                // dispatch({
                //   type: "SET",
                //   value: values,
                // })
                // setState("thanks")
              }}
            />
          </Styled.OverlayCard>
        </Overlay>

        {tours ? (
          <Styled.Form
            name="book"
            onSubmit={handleSubmit(onSubmit)}
            data-netlify="true"
          >
            <Styled.Hero></Styled.Hero>
            <Styled.HeroText>
              <h2 className="bp3-heading">ジョイナビ - 予約フォーム</h2>
            </Styled.HeroText>
            <Styled.Main>
              <FormGroup
                helperText=""
                label="ツアー名"
                labelFor="tour"
                labelInfo=""
              >
                <div className="bp3-select">
                  <select
                    className="bp3-select"
                    name="tour"
                    id="tour"
                    ref={register}
                  >
                    <option value={null}>
                      ご希望のツアーを選択してください
                    </option>
                    {tours.map((o, i) => {
                      const { name } = o.node.childMarkdownRemark.frontmatter
                      return (
                        <option value={name} key={i}>
                          {name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </FormGroup>

              <img src={getItemByKey(activeTour, "thumbnail")} />
              <div>
                {watchTour && (
                  <MarkdownContent>
                    {getItemByKey(activeTour, "description")}
                  </MarkdownContent>
                )}
              </div>

              <hr />

              <h2 className="bp3-heading">ご予約情報</h2>

              <FormGroup
                helperText={errors.lastName && "名字は必須です"}
                label="名字"
                labelFor="lastName"
                labelInfo="必須"
              >
                <InputGroup
                  id="name"
                  name="lastName"
                  intent={errors.lastName ? "danger" : null}
                  placeholder="田中"
                  inputRef={register({ required: true, maxLength: 100 })}
                />
              </FormGroup>

              <FormGroup
                helperText={errors.firstName && "名前は必須です"}
                label="名前"
                labelFor="lastName"
                labelInfo="必須"
              >
                <InputGroup
                  id="name"
                  name="firstName"
                  intent={errors.firstName ? "danger" : null}
                  placeholder="太郎"
                  inputRef={register({ required: true, maxLength: 100 })}
                />
              </FormGroup>

              <FormGroup
                helperText={errors.nameRoma && "お名前(ローマ字)は必須です"}
                label="お名前(ローマ字)"
                labelFor="nameRoma"
                labelInfo="必須"
              >
                <InputGroup
                  id="nameRoma"
                  name="nameRoma"
                  intent={errors.name ? "danger" : null}
                  placeholder="TARO TANAKA"
                  inputRef={register({ required: true, maxLength: 100 })}
                />
              </FormGroup>

              <FormGroup
                helperText={errors.birthday && "生年月日は必須です"}
                label="生年月日"
                labelFor="birthday"
                labelInfo="必須"
              >
                <Controller
                  as={DateInput}
                  name="birthday"
                  control={control}
                  locale="ja"
                  localeUtils={MomentLocaleUtils}
                  formatDate={date => moment(date).format("YYYY/MM/DD")}
                  // onChange={onChangeDate}
                  parseDate={str => new Date(str)}
                  placeholder={"YYYY/MM/DD"}
                  minDate={moment().subtract("years", 100).toDate()}
                  // value={date}
                  rules={{ required: true }}
                />
              </FormGroup>

              <FormGroup
                helperText={errors.tel && "電話番号は必須です"}
                label="電話番号"
                labelFor="tel"
                labelInfo="必須"
              >
                <InputGroup
                  id="tel"
                  name="tel"
                  placeholder="090-0000-0000"
                  inputRef={register({ required: true, maxLength: 100 })}
                />
              </FormGroup>

              <FormGroup
                helperText={errors.email && "メールアドレスは必須です"}
                label="メールアドレス"
                labelFor="email"
                labelInfo="必須"
              >
                <InputGroup
                  id="email"
                  name="email"
                  placeholder="example@gmail.com"
                  inputRef={register({ required: true, maxLength: 100 })}
                />
              </FormGroup>

              <FormGroup
                helperText=""
                label="現地連絡手段"
                labelFor="contactTool"
                labelInfo=""
              >
                <HTMLSelect
                  name={"contactTool"}
                  id="contactTool"
                  ref={register()}
                >
                  <option value="Eメール">Eメール</option>
                  <option value="Line">Line</option>
                  <option value="Skype">Skype</option>
                  <option value="WhatsApp">WhatsApp</option>
                </HTMLSelect>
              </FormGroup>

              <FormGroup
                helperText=""
                label="現地連絡手段のID"
                labelFor="contactToolId"
                labelInfo=""
              >
                <InputGroup
                  id="contactTool"
                  placeholder=""
                  name="contactToolId"
                  inputRef={register}
                />
              </FormGroup>
              <FormGroup
                helperText=""
                label="ツアー参加希望日"
                labelFor="date"
                labelInfo=""
              >
                <Controller
                  as={DateInput}
                  name="date"
                  control={control}
                  locale="ja"
                  localeUtils={MomentLocaleUtils}
                  formatDate={date => moment(date).format("YYYY/MM/DD")}
                  // onChange={onChangeDate}
                  parseDate={str => new Date(str)}
                  placeholder={"YYYY/MM/DD"}
                  minDate={
                    moment().hour(19).diff(moment())
                      ? moment().add("days", 1).toDate()
                      : moment().add("days", 2).toDate()
                  }
                  // value={date}
                />
              </FormGroup>

              <FormGroup
                helperText="エリアによっては追加送迎代金が発生する場合があります"
                label="送迎先"
                labelFor="pickupPoint"
                labelInfo="(正式なホテル名をご入力ください)"
              >
                <InputGroup
                  id="pickupPoint"
                  name="pickupPoint"
                  placeholder=""
                  inputRef={register}
                />
              </FormGroup>

              <FormGroup helperText="" label="同行者" labelFor="" labelInfo="">
                <FormGroup>
                  {fields.map((o: any, i: number) => {
                    return (
                      <Card
                        interactive={true}
                        elevation={Elevation.TWO}
                        key={o.id}
                      >
                        <h4 className="bp4-heading">同行者{i + 1}</h4>
                        <FormGroup
                          helperText=""
                          label="お名前(ローマ字)"
                          labelFor="name"
                          labelInfo="(必須)"
                        >
                          <InputGroup
                            id="name"
                            placeholder=""
                            name="name"
                            inputRef={register({
                              required: "required",
                            })}
                            required
                          />
                        </FormGroup>
                        <FormGroup
                          helperText=""
                          label="性別"
                          labelFor="sex"
                          labelInfo=""
                        >
                          <div className="bp3-select">
                            <select
                              className="bp3-select"
                              name={`companions[${i}].sex`}
                              id="sex"
                              ref={register()}
                            >
                              <option value="男性">男性</option>
                              <option value="女性">女性</option>
                              <option value="その他">その他</option>
                            </select>
                          </div>
                        </FormGroup>
                        <FormGroup
                          helperText=""
                          label="誕生日"
                          labelFor="birthday"
                          labelInfo=""
                        >
                          <Controller
                            as={DateInput}
                            name={`companions[${i}].birthday`}
                            control={control}
                            locale="ja"
                            localeUtils={MomentLocaleUtils}
                            formatDate={date =>
                              moment(date).format("YYYY/MM/DD")
                            }
                            // onChange={onChangeDate}
                            parseDate={str => new Date(str)}
                            placeholder={"YYYY/MM/DD"}
                            // value={date}
                          />
                        </FormGroup>
                        <Button
                          type="button"
                          intent="danger"
                          onClick={() => remove(i)}
                        >
                          同行者を削除
                        </Button>
                      </Card>
                    )
                  })}
                </FormGroup>
                <FormGroup>
                  <Button
                    type="button"
                    className="bp3-icon-add"
                    onClick={onPressAddCompanions}
                  >
                    同行者を追加
                  </Button>
                </FormGroup>
              </FormGroup>

              <FormGroup
                helperText=""
                label="連絡事項"
                labelFor="remark"
                labelInfo=""
              >
                <TextArea
                  id="remark"
                  name="remark"
                  inputRef={register}
                  growVertically={true}
                  large={true}
                  intent={Intent.PRIMARY}
                  // onChange={this.handleChange}
                  // value={this.state.value}
                />
              </FormGroup>

              {/* <div id="payment"></div> */}

              <a href="/tokushoho" target="_blank">
                特定商取引法に基づく表記
              </a>

              <Styled.Section>
                <Button type="submit" className="">
                  送信
                </Button>
              </Styled.Section>

              <Styled.Section></Styled.Section>
            </Styled.Main>
          </Styled.Form>
        ) : (
          <p>読込中</p>
        )}

        <form
          style={{ display: "none" }}
          target="hss_iframe"
          name="form_iframe"
          method="post"
          // productoin
          // action="https://securepayments.paypal.com/webapps/HostedSoleSolutionApp/webflow/sparta/hostedSoleSolutionProcess"
          // sandbox
          action="https://securepayments.sandbox.paypal.com/webapps/HostedSoleSolutionApp/webflow/sparta/hostedSoleSolutionProcess"
        >
          <input type="hidden" name="cmd" value="_hosted-payment" />
          <input
            type="hidden"
            name="subtotal"
            value={getItemByKey(activeTour, "price")}
          />
          <input type="hidden" name="currency_code" value="JPY" />
          {/* 本番アカウント */}
          {/* <input type="hidden" name="business" value="6AG4H7BLSQ46S" /> */}
          {/* sandboxアカウント */}
          <input type="hidden" name="business" value="EWXYVRR2PH752" />
          <input type="hidden" name="paymentaction" value="sale" />
          <input
            type="hidden"
            name="template"
            value={isPc ? Template.PC : Template.SP}
          />
          {/* <input
            type="hidden"
            name="billing_address1"
            value="BillingAddress1"
          />
          <input
            type="hidden"
            name="billing_address2"
            value="BillingAddress2"
          /> */}
          {/* <input type="hidden" name="billing_city" value="Setagaya-ku" /> */}
          <input type="hidden" name="billing_country" value="JP" />
          <input
            type="hidden"
            name="billing_first_name"
            value={watchFirstName}
          />
          <input type="hidden" name="billing_last_name" value={watchLastName} />
          {/* <input type="hidden" name="billing_state" value="Tokyo" />
          <input type="hidden" name="billing_zip" value="123-4567" /> */}
        </form>
      </div>
    )
  }
  return <Thanks />
}

function makeRequest(values) {
  console.log(makeRequest)
  return fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({
      "form-name": "book",
      ...values,
    }),
  })
    .then(() => alert("送信しました"))
    .catch(error => alert(error))
}
